import React from 'react'
import Slick from 'react-slick'

import { path } from '@/core/utils'
import { useApp } from '@/core/contexts/app'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image/Image'
import Icon from '@/components/base/icon/Icon'

import { SliderProps } from './type'
import ScrollDown from '../scroll-down/ScrollDown'
import Anchor from '@/components/base/anchor/Anchor'

const Slider: React.FunctionComponent<SliderProps> = ({ data }) => {
  const app = useApp()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className="slider">
      {/* <div
        style={{
          position: 'absolute',
          backgroundColor: '#fff',
          bottom: '100px',
          right: '50px',
          zIndex: 99,
          border: '1px solid #fff',
          borderRadius: '20px',
        }}
      >
        <div
          style={{
            width: '225px',
            height: '450px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <b style={{ alignSelf: 'end', padding: '10px' }}>
            <Icon name="cancel" size={'large'} />
          </b>
          <b>
            <p>
              Yeni Tarifemizle <br />
              <span style={{ fontSize: '1.3rem' }}>
                EVİNİZDE VE İŞYERİNİZDE ŞARJ
              </span>
            </p>
          </b>
          <b>
            <div>
              <span
                style={{
                  position: 'absolute',
                  top: '200px',
                  left: '30px',
                  fontSize: '2rem',
                }}
              >
                %
              </span>
              <div style={{ fontSize: '8rem' }}>20</div>
              <div style={{ fontSize: '2rem' }}>İNDİRİMLİ!</div>
            </div>
          </b>
          <Anchor className="btn">ÜYE OL</Anchor>
          <figure>
            <img
              style={{
                position: 'absolute',
                right: '0px',
                bottom: '-50px',
                width: '100px',
                backgroundColor: '#B11F31',
                borderRadius: '50%',
                border: '5px solid #fff',
              }}
              src="https://cdn-icons-png.flaticon.com/512/46/46010.png"
              alt="test"
            />
          </figure>
        </div>
      </div> */}
      <Slick {...settings}>
        {data?.map((item, index) => (
          <div key={index}>
            <div
              className="item"
              style={{
                backgroundImage: `url(${path.asset(
                  app.environment,
                  item.backgroundImage.src
                )})`,
              }}
            >
              <Container size="medium">
                <Row xs={{ align: 'center', justify: 'center' }}>
                  <Column xs={{ size: 12 }}>
                    <div className="title">
                      {item.title} <span>{item.subTitle}</span>
                    </div>
                    <figure>
                      <Image {...item.image} alt={item.title} />
                    </figure>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                      className="desc"
                    />
                  </Column>
                </Row>
              </Container>
            </div>
          </div>
        ))}
      </Slick>
      <ScrollDown />
    </div>
  )
}

export default Slider
