import React from 'react'

import Button from '@/components/base/button/Button'
import Icon from '@/components/base/icon/Icon'

const ScrollDown = () => {
  const handleScrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }

  return (
    <Button onClick={handleScrollDown} className="scroll-down">
      <Icon name="double-arrow" />
    </Button>
  )
}

export default ScrollDown
